import React from 'react';
import { useQuery } from 'react-query';
import cn from 'classnames';

import useUser from '../../../hooks/useUser';
import PaymentService from '../../../services/PaymentService';
import classes from './styles.module.scss';

const PaymentHeader = () => {
  const { isServiceProvider } = useUser();

  const { data } = useQuery('balance', PaymentService.getBalance, {
    enabled: isServiceProvider,
  });

  return (
    <>
      <div className={classes.PaymentHeader}>
        <div className={classes.flexLeftContainer}>
          <div className={classes.infoBox}>
            <span className={classes.label}>In Progress</span>
            <span className={classes.value}>
              $
              {data?.balance?.inProgress
                ? data.balance.inProgress.toFixed(2)
                : '0.00'}
            </span>
          </div>
          <div className={classes.infoBox}>
            <span className={classes.label}>In Review</span>
            <span className={classes.value}>
              $
              {data?.balance?.inReview
                ? data.balance.inReview.toFixed(2)
                : '0.00'}
            </span>
          </div>
        </div>
        <div className={classes.flexRightContainer}>
          <div className={cn(classes.infoBox, classes.totalEarningInfoBox)}>
            <span className={classes.label}>Total Earnings</span>
            <span className={classes.value}>
              $
              {data?.balance?.totalEarning
                ? data.balance.totalEarning.toFixed(2)
                : '0.00'}
            </span>
          </div>
        </div>
      </div>
      {(data?.inProgressDetails.length > 0 ||
        data?.inReviewDetails.length > 0) && (
        <div className={classes.PaymentDetails}>
          {data?.inProgressDetails.length > 0 && (
            <div className={classes.details}>
              <span className={classes.label}>
                In Progress - This Weeks Engagement
              </span>
              {data.inProgressDetails.map((x) => (
                <span className={classes.value}>{x}</span>
              ))}
            </div>
          )}
          {data?.inReviewDetails.length > 0 && (
            <div className={classes.details}>
              <span className={classes.label}>In Review For Payout</span>
              {data.inReviewDetails.map((x) => (
                <span className={classes.value}>{x}</span>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PaymentHeader;
