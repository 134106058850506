import React from 'react';
import { Link } from 'react-router-dom';
import { PiChatCircleDotsThin } from 'react-icons/pi';
import { useSession } from '@talkjs/react';
import useUser from '../../hooks/useUser';
import logo from '../../assets/images/logo-white.svg';
import classes from './styles.module.scss';
import useCheckDesktopScreen from '../../hooks/useCheckDesktopScreen';

const TopHeader = ({ unreadMessageCount }) => {
  const { isCustomer } = useUser();
  const session = useSession();
  const isDesktop = useCheckDesktopScreen();

  const onChatButtonClick = () => {
    if (session?.isAlive) {
      const inboxes = session.getInboxes();
      inboxes.forEach((inbox) => {
        inbox.select(null);
      });
    }
  };

  return (
    <div className={classes.TopHeaderContainer}>
      <div className={classes.content}>
        <div className={classes.left}>
          <Link to="/getting-started">
            <img src={logo} alt="LevelDo" className={classes.logo} />
          </Link>
        </div>
        <div className={classes.right}>
          {isDesktop && isCustomer && (
            <Link
              target="_blank"
              to={{ pathname: 'https://form.jotform.com/240905257404049' }}
              className={classes.Link}
            >
              Create A New Engagement
            </Link>
          )}
          <Link
            target="_blank"
            to={{ pathname: 'https://form.jotform.com/240906087684061' }}
            className={classes.LinkButton}
          >
            New Engagement or Get Support
          </Link>
          {!isDesktop && (
            <Link
              className={classes.messageBoxLink}
              onClick={onChatButtonClick}
              to="/chat"
            >
              <PiChatCircleDotsThin size={25} color="#fff" />
              {unreadMessageCount > 0 && (
                <span className={classes.unreadMessageCount}>
                  {unreadMessageCount}
                </span>
              )}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
